// .js-faq-event-handlerがクリックされたらtoggleFaqを発火するようにイベントを登録する
document.addEventListener("DOMContentLoaded", async () => {
  const faqEventHandler = document.querySelectorAll(".js-faq-event-handler");
  faqEventHandler.forEach((handler) => {
    handler.addEventListener("click", toggleFaq);
  });
});

const toggleFaq = (e: Event) => {
  let target = e.target as HTMLElement;
  if (!target) return;
  // targetが.js-faq-event-handlerを持っていなければ上を探しに行く
  if (!target.classList.contains("js-faq-event-handler")) {
    const parent = target.closest(".js-faq-event-handler");
    if (!parent) return;
    target = parent as HTMLElement;
  }
  // .style-closedがあればそれを削除し、なければ付与する
  if (target.classList.contains("style-closed")) {
    //
    // 開く
    //
    // targetをdupさせる
    const targetDup = target.cloneNode(true) as HTMLElement;
    // targetDupに.style-closedを削除する
    targetDup.classList.remove("style-closed");
    // #js-temp-drawに描画をする
    const tempDraw = document.getElementById("js-temp-draw");
    if (!tempDraw) return;
    tempDraw.appendChild(targetDup);
    // 開く予定の高さを取得する
    const toHeight = targetDup.clientHeight;
    // targetDupを削除する
    tempDraw.removeChild(targetDup);
    // 現状の高さを取得する
    const fromHeight = target.clientHeight;
    // 現状の高さを設定する
    target.style.maxHeight = `${fromHeight + 1}px`; // border分 + 1
    // 遅延させてmaxHeightを設定する
    setTimeout(() => {
      target.style.maxHeight = `${toHeight}px`;
    }, 10);
    // 完了時にmaxHeightを削除する
    setTimeout(() => {
      target.style.maxHeight = "";
    }, 210);
    // 開く
    target.classList.remove("style-closed");
  } else {
    //
    // 閉じる
    //
    // targetをdupさせる
    const targetDup = target.cloneNode(true) as HTMLElement;
    // targetDupに.style-closedを付与する
    targetDup.classList.add("style-closed");
    // #js-temp-drawに描画をする
    const tempDraw = document.getElementById("js-temp-draw");
    if (!tempDraw) return;
    tempDraw.appendChild(targetDup);
    // 閉じる予定の高さを取得する
    const toHeight = targetDup.clientHeight;
    // targetDupを削除する
    tempDraw.removeChild(targetDup);
    // 現状の高さを取得する
    const fromHeight = target.clientHeight;
    // 現状の高さを設定する
    target.style.maxHeight = `${fromHeight}px`;
    // 遅延させてmaxHeightを設定する
    setTimeout(() => {
      target.style.maxHeight = `${toHeight + 1}px`; // border分 + 1
    }, 10);
    // 完了時にmaxHeightを削除する
    setTimeout(() => {
      target.style.maxHeight = "";
      target.classList.add("style-closed");
    }, 210);
    // 閉じる
  }
};
